.hoox-steps {
	padding: 25px 0;
	background: var( --hoox-color-white);
}
.hoox-steps__wrapper {
	background: #FCEE9C;
	padding: 25px 20px;
}
.hoox-steps__title {
	color: #414D57;
	font-size: 28px;
	font-weight: 400;
	text-align: left;
	margin-bottom: 24px;
}
.hoox-steps__subtitle {
	margin-bottom: 24px;
}
.hoox-steps__item {
	border-bottom: 1px solid #E8DB91;
	margin-bottom: 24px;
}
.hoox-step__video { }
.hoox-step__video video {
	width: 100%;
	height: 100%;
}
.hoox-step__title {
	font-size: 20px;
	color: #414D57;
	font-weight: 400;
	margin-bottom: 8px;
}
.hoox-step__number {
	font-size: 20px;
	color: #414D57;
	font-weight: 400;
}
.hoox-step__text {
	font-size: 16px;
	font-weight: 400;
	color:#414D57;
	margin-bottom: 12px;
}
.hoox-steps-lp-3 .hoox-steps__item:last-of-type .hoox-step__text {
	margin-bottom: 0;
}
.hoox-steps-lp-3 .hoox-steps__item:last-of-type {
	margin-bottom: 0;
}

@media(min-width: 768px) {
	.hoox-steps{
		padding: 60px 0;
	}
	.hoox-steps__wrapper {
		padding: 48px;
	}

	.hoox-steps__title{
		font-size: 40px;
		text-align: center;
		margin-bottom: 40px;
	}

	.hoox-steps__subtitle {
		margin-bottom: 40px;
	}

	.hoox-steps__items {
		display: flex;
		flex-direction: row;
		gap: 30px;
		justify-content: center;
	}
	.hoox-steps__item {
		border-bottom: none;
		max-width: 348px;
		width: 100%;
	}

	.hoox-step__video {
		height: 348px;
		margin-bottom: 24px;
	}
	.hoox-step__text {
		max-width: 348px;
		margin-bottom: 0px;
	}

	.hoox-steps__btn {
		max-width: 350px;
		margin: 15px auto 0;
	}
}


/*Desktop*/

@media(min-width: 768px) {
	.hoox-steps{
		padding: 60px 0;
	}
	.hoox-steps__wrapper {
		padding: 48px;
	}

	.hoox-steps__title{
		font-size: 40px;
		text-align: center;
		margin-bottom: 40px;

	}

	.hoox-steps__items {
		display: flex;
		flex-direction: row;
		gap: 30px;
		justify-content: center;
	}
	.hoox-steps__item {
		border-bottom: none;
		max-width: 348px;
		width: 100%;
	}

	.hoox-step__video {
		height: 348px;
		margin-bottom: 24px;
	}
	.hoox-step__text {
		max-width: 348px;
		margin-bottom: 0px;
	}

	.hoox-steps__btn {
		max-width: 350px;
		min-width: 350px;
		margin: 15px auto 0;
	}
}

/*Tablet / portrait vies*/

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation:portrait) {
	.hoox-steps__item {
		margin-bottom: 24px;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.hoox-steps__title {
		text-align: center;
	}

	.hoox-steps__items {
		display: flex;
		flex-direction: column;
		gap: 30px;
		justify-content: center;
		align-items: center;
	}
}

.hoox-steps-lp-3 {
	padding: 48px 0;
}

.hoox-steps-lp-3 .hoox-steps__wrapper {
	background: var( --hoox-color-slate-tertiary);
	padding: 18px 21px;
}

.hoox-steps-lp-3 .hoox-steps__title {
	line-height: 36px;
	text-align: center;
}

.hoox-steps-lp-3 .hoox-steps__subtitle {
	max-width: 230px;
    margin-inline: auto;
}

.hoox-steps-lp-3 .hoox-step__video, .hoox-steps-lp-3 .hoox-step__image {
	height: 305px;
	margin-bottom: 13px;
}

.hoox-steps-lp-3 .hoox-steps__item {
	border-bottom: unset;
	margin-bottom: 22px;
}

.hoox-steps-lp-3 .hoox-step__text {
	max-width: 270px;
}

@media (min-width: 768px) {
	.hoox-steps-lp-3 {
		padding: 80px 0 ;
	}
	.hoox-steps-lp-3 .hoox-steps__wrapper {
		padding: 48px;
	}
	.hoox-steps-lp-3 .hoox-steps__subtitle {
		max-width: unset;
	}
	.hoox-steps-lp-3 .hoox-steps__title {
		margin-bottom: 18px !important;
	}
	.hoox-steps-lp-3 .hoox-steps__items {
		gap: 64px;
	}
	.hoox-steps-lp-3 .hoox-step__video, .hoox-steps-lp-3 .hoox-step__image {
		height: 350px;
		margin-bottom: 21px;
	}
	.hoox-steps-lp-3 .hoox-step__text {
		max-width: unset;
	}
	.hoox-steps-lp-3 .hoox-steps__item {
		margin-bottom: 0;
	}
}